var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Simple", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("Simple Draggable list with in sync to original list. Remove "),
        _c("code", [_vm._v("list")]),
        _vm._v(" prop to break synchronization with original list."),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c(
            "vs-list",
            [
              _c(
                "draggable",
                { staticClass: "cursor-move", attrs: { list: _vm.list } },
                _vm._l(_vm.list, function (item, index) {
                  return _c("vs-list-item", {
                    key: index,
                    staticClass: "flex items-center p-1",
                    attrs: { title: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("prism", { staticClass: "rounded-lg", attrs: { language: "js" } }, [
        _vm._v("\n" + _vm._s(_vm.list) + "\n        "),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <vs-list>\n    <draggable :list="list" class="cursor-move">\n      <vs-list-item class="flex items-center p-1" v-for="(item, index) in list" :key="index" :title="item">\n      </vs-list-item>\n    </draggable>\n  </vs-list>\n</template>\n\n<script>\nimport draggable from \'vuedraggable\'\n\nexport default {\n  data() {\n    return {\n      list: [\n        "Marzipan I love I love. Soufflé donut I love gummi bears powder. Candy danish biscuit.",\n        "Halvah bonbon bonbon brownie sugar plum. Halvah I love cupcake I love.",\n        "Cake muffin icing topping wafer topping gummi bears apple pie.",\n        "Cotton candy gummi bears bear claw cake brownie jelly-o lemon drops croissant sweet roll.",\n      ]\n    }\n  },\n  components: {\n    draggable,\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }