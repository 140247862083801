var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "With Animation", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("Add animation to changes using "),
        _c("code", [_vm._v("transition-group")]),
        _vm._v("."),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c(
            "vs-list",
            [
              _c("vs-list-header", {
                attrs: { title: "People Group", color: "primary" },
              }),
              _c(
                "draggable",
                { staticClass: "cursor-move", attrs: { list: _vm.list } },
                [
                  _c(
                    "transition-group",
                    _vm._l(_vm.list, function (listItem) {
                      return _c(
                        "vs-list-item",
                        {
                          key: listItem.email,
                          staticClass: "list-item",
                          attrs: {
                            title: listItem.name,
                            subtitle: listItem.email,
                          },
                        },
                        [
                          _c("vs-avatar", {
                            attrs: { slot: "avatar", text: listItem.name },
                            slot: "avatar",
                          }),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("prism", { staticClass: "rounded-lg", attrs: { language: "js" } }, [
        _vm._v("\n" + _vm._s(_vm.list) + "\n        "),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <vs-list>\n    <vs-list-header title="People Group" color="primary"></vs-list-header>\n\n    <draggable :list="list">\n      <transition-group>\n        <vs-list-item class="list-item" v-for="listItem in list" :key="listItem.email" :title="listItem.name" :subtitle="listItem.email">\n          <vs-avatar slot="avatar" :text="listItem.name" />\n        </vs-list-item>\n      </transition-group>\n    </draggable>\n\n  </vs-list>\n</template>\n<script>\nimport draggable from \'vuedraggable\'\n\nexport default {\n  data() {\n    return {\n      list: [{\n          name: "Paz Joya",\n          email: "girliness@spotlike.co.uk",\n        },\n        {\n          name: "Sunshine Cose",\n          email: "executrixship@equisized.edu",\n        },\n        {\n          name: "Alba Dobbin",\n          email: "bidding@demibob.or",\n        },\n        {\n          name: "Marlin Hinchee",\n          email: "preholding@scuffly.co.uk",\n        },\n        {\n          name: "Leia Atienza",\n          email: "unmeasurableness@interlamellar.co.uk"\n        },\n        {\n          name: "Lashawna Vaudrainm",\n          email: "soaking@khubber.com"\n        },\n        {\n          name: "Liliana Henscheid",\n          email: "lecideine@turndown.org"\n        },\n        {\n          name: "Keven Kolter",\n          email: "nontenure@anglicanum.co.uk"\n        }\n      ],\n    }\n  },\n  components: {\n    draggable\n  }\n}\n</script>\n<style>\n.list-item {\n  transition: all 1s\n}\n</style>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }