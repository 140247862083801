var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Multiple Lists", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("Drag and drop items of more than one list. Add same "),
        _c("code", [_vm._v("group")]),
        _vm._v(" to "),
        _c("code", [_vm._v("group")]),
        _vm._v(" prop"),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full md:w-1/2" },
          [
            _c(
              "vs-list",
              [
                _c("vs-list-header", {
                  attrs: { title: "People Group 1", color: "primary" },
                }),
                _c(
                  "draggable",
                  {
                    staticClass: "p-2 cursor-move",
                    attrs: { list: _vm.list1, group: "people" },
                  },
                  _vm._l(_vm.list1, function (listItem, index) {
                    return _c(
                      "vs-list-item",
                      {
                        key: index,
                        attrs: {
                          title: listItem.name,
                          subtitle: listItem.email,
                        },
                      },
                      [
                        _c("vs-avatar", {
                          attrs: { slot: "avatar", text: listItem.name },
                          slot: "avatar",
                        }),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-full md:w-1/2" },
          [
            _c(
              "vs-list",
              [
                _c("vs-list-header", {
                  attrs: { title: "People Group 2", color: "primary" },
                }),
                _c(
                  "draggable",
                  {
                    staticClass: "p-2 cursor-move",
                    attrs: { list: _vm.list2, group: "people" },
                  },
                  _vm._l(_vm.list2, function (listItem, index) {
                    return _c(
                      "vs-list-item",
                      {
                        key: index,
                        attrs: {
                          title: listItem.name,
                          subtitle: listItem.email,
                        },
                      },
                      [
                        _c("vs-avatar", {
                          attrs: { slot: "avatar", text: listItem.name },
                          slot: "avatar",
                        }),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full md:w-1/2" },
          [
            _c(
              "prism",
              { staticClass: "rounded-lg", attrs: { language: "js" } },
              [
                _vm._v(
                  "\nPeople Group 1: " +
                    _vm._s(_vm.list1) +
                    "\n                "
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-full md:w-1/2" },
          [
            _c(
              "prism",
              { staticClass: "rounded-lg", attrs: { language: "js" } },
              [
                _vm._v(
                  "\nPeople Group 2: " +
                    _vm._s(_vm.list2) +
                    "\n                "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <vs-list>\n    <vs-list-header title="People Group 1" color="primary"></vs-list-header>\n\n    <draggable :list="list1" group="people" class="p-2 cursor-move">\n      <vs-list-item v-for="(listItem, index) in list1" :key="index" :title="listItem.name" :subtitle="listItem.email">\n        <vs-avatar slot="avatar" :text="listItem.name" />\n      </vs-list-item>\n    </draggable>\n\n  </vs-list>\n\n  <vs-list class="mt-5">\n    <vs-list-header title="People Group 2" color="primary"></vs-list-header>\n\n    <draggable :list="list2" group="people" class="p-2 cursor-move">\n      <vs-list-item v-for="(listItem, index) in list2" :key="index" :title="listItem.name" :subtitle="listItem.email">\n        <vs-avatar slot="avatar" :text="listItem.name" />\n      </vs-list-item>\n    </draggable>\n\n  </vs-list>\n</template>\n\n<script>\nimport draggable from \'vuedraggable\'\n\nexport default {\n  data() {\n    return {\n      list1: [\n        {\n          name: "Paz Joya",\n          email: "girliness@spotlike.co.uk",\n        },\n        {\n          name: "Sunshine Cose",\n          email: "executrixship@equisized.edu",\n        },\n        {\n          name: "Alba Dobbin",\n          email: "bidding@demibob.or",\n        },\n        {\n          name: "Marlin Hinchee",\n          email: "preholding@scuffly.co.uk",\n        }\n      ],\n      list2: [\n        {\n          name: "Leia Atienza",\n          email: "unmeasurableness@interlamellar.co.uk"\n        },\n        {\n          name: "Lashawna Vaudrainm",\n          email: "soaking@khubber.com"\n        },\n        {\n          name: "Liliana Henscheid",\n          email: "lecideine@turndown.org"\n        },\n        {\n          name: "Keven Kolter",\n          email: "nontenure@anglicanum.co.uk"\n        }\n      ]\n    }\n  },\n  components: {\n    draggable,\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }