var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Clone List", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("Clone list items from another existing list. Add "),
        _c("code", [_vm._v("pull")]),
        _vm._v(" and "),
        _c("code", [_vm._v("put")]),
        _vm._v(" to "),
        _c("code", [_vm._v("group")]),
        _vm._v(" prop"),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full sm:w-1/2" },
          [
            _c("h6", { staticClass: "mt-5 mb-3" }, [_vm._v("Tag Source")]),
            _c(
              "draggable",
              {
                staticClass: "p-2 cursor-move",
                attrs: {
                  list: _vm.list1,
                  group: { name: "tags", pull: "clone", put: false },
                },
              },
              _vm._l(_vm.list1, function (listItem, index) {
                return _c("vs-chip", { key: index }, [_vm._v(_vm._s(listItem))])
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-full sm:w-1/2" },
          [
            _c("h6", { staticClass: "mt-5 mb-3" }, [_vm._v("Your Tags")]),
            _c(
              "draggable",
              {
                staticClass: "p-2 cursor-move",
                attrs: { list: _vm.list2, group: { name: "tags" } },
              },
              _vm._l(_vm.list2, function (listItem, index) {
                return _c("vs-chip", { key: index }, [_vm._v(_vm._s(listItem))])
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full sm:w-1/2" },
          [
            _c(
              "prism",
              { staticClass: "rounded-lg", attrs: { language: "js" } },
              [
                _vm._v(
                  "\nTag Source: " + _vm._s(_vm.list1) + "\n                "
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-full sm:w-1/2" },
          [
            _c(
              "prism",
              { staticClass: "rounded-lg", attrs: { language: "js" } },
              [
                _vm._v(
                  "\nAdded tags: " + _vm._s(_vm.list2) + "\n                "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <div>\n    <h6 class="mt-5 mb-3">Tag Source</h6>\n    <draggable :list="list1" :group="{name:\'tags\',  pull:\'clone\', put:false }" class="p-2 cursor-move">\n      <vs-chip v-for="(listItem, index) in list1" :key="index"> ' +
            _vm._s("{{ listItem }}") +
            ' </vs-chip>\n    </draggable>\n  </div>\n  <div>\n    <h6 class="mt-5 mb-3">Your Tags</h6>\n    <draggable :list="list2" :group="{name: \'tags\'}" class="p-2 cursor-move">\n      <vs-chip v-for="(listItem, index) in list2" :key="index"> ' +
            _vm._s("{{ listItem }}") +
            ' </vs-chip>\n    </draggable>\n  </div>\n</template>\n\n<script>\nimport draggable from \'vuedraggable\'\n\nexport default {\n  data() {\n    return {\n      list1: ["youtube", "google", "facebook", "twitter", "instagram", "pinterest", "tinder", "slack", "discord", "github", "gitlab"],\n      list2: ["google", "youtube"]\n    }\n  },\n  components: {\n    draggable,\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }