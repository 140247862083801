var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "extra-component-drag-and-drop-demo" } },
    [
      _vm._m(0),
      _c("drag-and-drop-simple"),
      _c("drag-and-drop-multiple-lists"),
      _c("drag-and-drop-clone-list"),
      _c("drag-and-drop-animation"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-4" }, [
      _c(
        "a",
        {
          attrs: {
            href: "https://github.com/SortableJS/Vue.Draggable",
            target: "_blank",
            rel: "nofollow",
          },
        },
        [_vm._v("Vue.Draggable")]
      ),
      _vm._v(
        " - Vue component allowing drag-and-drop and synchronization with view model array. Based on and offering all features of Sortable.js"
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }